import sections from './../../../assets/data/mineralSections.json';
import rockGlossary from '../../../assets/data/rockGlossary.json';

export default {
    state(){
        return {
            currentLanguage: '',
            languageBtnOptions: sections.button,
            translate: sections.translate,
            suffixFix: sections.ptAdjustSuffix,
            rockGlossary
        }       
    },
    mutations:{
        /**
         * set the state of currentLanguage
         * @param {String} payload ('en' or 'pt')
         */
        setCurrentLanguage(state, payload) {
            state.currentLanguage = payload;
        }
    },
    actions: {
        /**
         * modify state
         * @param {String} payload ('en' or 'pt')
         */
        setCurrentLanguage(context, payload){
            let language = payload;
            if (payload !== 'en' && payload !== 'pt') {
                language = 'en';
            } 
            context.commit('setCurrentLanguage', language);
        }
    },
    getters:{
        /**
         * @returns 'en' or 'pt'
         */
        getCurrentLanguage(state){
            return state.currentLanguage;
        },
        /**
         * @returns {Array}: {String} 'pt' or 'en' and {String} 'português' or 'english'
         */
        languageBtn(state){
            return Object.values(state.languageBtnOptions[state.currentLanguage]);
        },
        /**
         * @returns {Object} of glossary, where key is in english and value is in portuguese
         */
        getTranslate(state){
            return state.translate;
        }
    }
}