<template>
    <div class="border" :class="mode">
        <div class="content" >
            <slot></slot>
        </div>
    </div>
    
</template>

<script>
export default {
    props: ['mode']
}
</script>

<style scoped>
.border {
  border: var(--color-grey-4) dashed 2px;
  position: relative; }

.content {
   
    /* background-color: var(--color-primary-3); */
    text-align: center;
    position: absolute;
    padding: 0.5rem 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--color-home-3);
    text-transform: uppercase;
    color: white;
}
/** minerals */
.card__filler {
    /* width: 14rem; */
    grid-row: 1/2;
    grid-column: 1/-1;
    margin: 1.5rem;
    border-radius: 1rem;
    overflow: hidden; 
}
.details__filler{
    width: 70%;
    grid-column: 1/2;
    height: 70%;
    margin: 5rem;
    border-radius: 2rem;    
}

/** rocks */
.rock__filler {
    /* width: 14rem; */
    grid-row: 1/2;
    grid-column: 1/-1;
    margin: 1.5rem;
    border-radius: 1rem;
    overflow: hidden; 
}


</style>