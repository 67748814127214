<template>
    <button class="btn btn__close" :type="type" :class="mode">
      
    </button>
</template>

<script>
export default {
    props: ['type', 'mode']
}
</script>

<style scoped>
.btn__close::before, .btn__close::after {
    width: 110%;
    height: 10%;
    background-color: var(--color-grey-4);
    position: absolute;
    top: 0.9rem;
    left: 0rem;
    content: ""; }
  .btn__close::before {
    transform: rotate(45deg);
    z-index: 10; }
  .btn__close::after {
    transform: rotate(-45deg);
    z-index: 9; }
.details {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    
}

</style>