<template>
    <div v-if="selectOption[1][0] === 'hardness' || selectOption[1][0] === 'density'" 
        class="select__input"  :data-option=selectOption[1][0]>
        <h4 class="heading-4 select__title">{{selectOption[0][0]}}:</h4>
            <form @submit.prevent="submitForm(selectOption[1][0], enteredNumber)">
                <label :for=selectOption[1][0]>Min:</label>
                 <input
                    type="number"
                    :class=selectOption[1][0]
                    :name=selectOption[1][0]
                    min="1"
                    :max="`${selectOption[1][0] === 'hardness' ? 10 : 20 }`"
                    placeholder="Min"
                    v-model="enteredNumber"
                    />
            </form> 
    </div>
    <div v-else class="select__option" :data-option=selectOption[1][0]>
        <button class="btn">
            <h4 class="heading-4 select__title">{{selectOption[0][0]}}</h4>
        </button>
        <div class="preview">
            <ul>
             <li v-for="selectItem in selectOption[0][1]" 
             :key="selectItem" 
             class="preview__item"
             @click="clickedItem(selectOption[1][0], selectOption[1][1][selectOption[0][1].indexOf(selectItem)])">{{selectItem}}
             </li>
            </ul>
        </div>
    </div>                      
</template>

<script setup>

import { defineEmits, defineProps, ref } from 'vue';

const emit = defineEmits(['selected-option']);

defineProps({ selectOption: Array });

let enteredNumber = ref(null);

/**
 * @returns {Array} queryArray of select and option in english e.g.: ['cleavage', 'perfect']
 */
function clickedItem(item, type){
    const queryArray = [item, type];
    emit('selected-option', queryArray)
    }

/**
 * @returns {Array} queryArray of hardness or density and input number in english e.g.: ['density', 9]
 */
function submitForm (it, numb){
    const item = it;
    const num = numb;
    const queryArray= [item, num];
    emit('selected-option', queryArray)
    enteredNumber.value=null;

}

</script>



<style scoped>
.select__input {
  display: flex;
  align-items: center;
  text-transform: uppercase; }


label {
    display: none; }
input {
    width: 5rem;
    margin: 0rem 0.5rem; }

.select__option{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; 
  
}
.preview {
    display: none;
    width: 15rem;
    position: absolute;
    /* width: 100%; */
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    top: 2.5rem;
    left: 0;
    z-index: 400; 
    }
.select__option:hover .preview {
    display: block;
}
ul {
    list-style: none;
    z-index: 400;
}

[data-option="lustre"] li:nth-child(n+3){
    padding-left: 1rem;
    text-transform: lowercase;
}
.preview__item:hover {
     background-color: var(--color-grey-2);
}
.preview__item {
    border-top: 1px solid var(--color-grey-2);
    text-transform: uppercase;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    color: var(--color-grey-4);
}

button {
    height: 95%;
  padding-right: 1rem;
  padding-left: 1rem; }
@media only screen and (max-width: 56.25em) {
    button {
      border: 1px solid var(--color-grey-3);
      border-radius: 3rem; } }

@media only screen and (max-width: 56.25em) {
  .button:hover {
    background-color: var(--color-primary-3); } }


</style>