<template>
  <router-view></router-view>
</template>

<script setup>

import {  onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

let browserLanguage = ref('');
const store = useStore();

/**
 * @returns the first two characters of a language, e.g.: 'en', 'pt'
 */
onBeforeMount(()=>{
  browserLanguage.value = navigator.language.slice(0,2);
  store.dispatch('setCurrentLanguage', browserLanguage.value);
})


</script>

<style>
:root {
  --font-primary: "Nunito", sans-serif;
  --font-display: "Josefin Sans", sans-serif;
  --color-home-1: #f1f6f9;
  --color-home-2: #14374e;
  --color-home-3: #394867;
  --color-home-4: #9ba4b4;
  --color-grey-1: #f4f4f2;
  --color-grey-2: #e8e8e8;
  --color-grey-3: #bbbfca;
  --color-grey-4: #495464;
  --color-primary-1: #ffc312;
  --color-primary-2: #c4e538;
  --color-primary-3: #12cbc4;
  --color-primary-4: #fda7df;
  --color-primary-5: #ed4c67;
  --color-primary-6: #facc15;
  --color-black: #000000;
  --color-white: #ffffff; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%; }
  @media only screen and (max-width: 81.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 64em) {
    html {
      font-size: 45%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 40%; } }

  @media only screen and (max-width: 23.75em) {
    html {
      font-size: 30%; } }



body {
  min-height: 100vh; }

body {
  font-family: var(--font-primary); }
body p {
    font-size: 2rem; }
body .home {
    font-size: 3rem;
    color: var(--color-home-2); }
body .footer {
    font-size: 1.5rem;
    color: var(--color-grey-4); }

h1 {
  font-size: 7rem; }

h2 {
  font-size: 5rem; }

h3 {
  font-size: 2.5rem; }

h4 {
  font-size: 2rem; }

h5 {
  font-size: 1.6rem; }

.btn {
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent; }


</style>