import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
// import mineralData from './../../../assets/data/minerals.json';
import sections from '../../../assets/data/mineralSections.json';
import mineralDatabase from '../../../assets/data/minerals.json'; //ok
import nameDatabase from '../../../assets/data/mineralNames.json'; //ok



export default {
    namespaced: true,
    state(){
        return {
            
            // mineral: {},
            // minerals: mineralData.data,
            mineralDatabase: mineralDatabase.minerals, // Json Object with all minerals
            nameDatabase: nameDatabase, // Json Object file with mineral names in 'en' and 'pt'
            title: { //Titles current possible in english and portuguese
                en: "minerals",
                pt: "minerais"
            },
            currentTitle: '', //title in the user language
            mineralNames: [], // Array of all mineral names in the user language
            hasMineral: true,
            oneMineral: {},
            querySuggestions:[], // Array of mineral names that match the user input
            query: '',
            queryArray:[], // Array of query from section and filter
            allSelectOptions: sections.sections, // Object of section categories and subcategories
            selectOptions: [], // Array of options in currentLanguage and english
            results: [] // Array of objects or One object of mineral in the user language
        }
    },
    mutations, 
    actions,
    getters
    
}