<template>
  <img :type="type" :class="mode" src="/img/logo/lupa_wave_col.png" />
</template>

<script>
export default {
  props: ["type", "mode"],
};
</script>

<style scoped>
.minerals__logo {
  width: 8rem;
  height: 8rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 2000;
}
@media only screen and (max-width: 26.5em) {
  .minerals__logo {
    width: 6rem;
    height: 6rem;
  }
}
.rocks__logo {
  /* background-color: white; */
  background: url("../../../public/img/logo/lupa_wave_col.png");
  width: 8rem;
  height: 8rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 2000;
}
@media only screen and (max-width: 26.5em) {
  .rocks__logo {
    width: 6rem;
    height: 6rem;
  }
}
.lupanalua {
  width: 8rem;
  height: 8rem;
  position: fixed;
  top: 5rem;
  left: 5rem;
  z-index: 2000;
}
</style>
