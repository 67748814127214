<template>
    <div class="all">
        <div class="title">
            <p>Not found / </p>
            <p>Não achei </p>
        </div>
        
        <navi-button mode="middle"></navi-button>
        
    </div>
</template>

<style scoped>
.all{
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-primary-1);
    width: 100vw;
    height: 100vh;
    z-index: 2000;
}

.title {
    
    margin: 20% 10%;
    text-align: center;
    
}

p {
   font-family: var(--font-primary);
   font-size: 8rem;
}

.home {
    margin: 10% 10%;
    font-size: 5rem;
    background-color: white;
    width: 50rem;
    border-radius: 3rem;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* @media only screen and (max-width: 31.25em) {
    .home {
        margin-left: 10rem;
    }
} */



</style>

