import { createStore } from "vuex";

import languageBtnModule from "./modules/language/index.js";
import mineralsModule from "./modules/minerals/index.js";

const store = createStore({
  modules: {
    language: languageBtnModule,
    minerals: mineralsModule,
  },
});

export default store;
