<template>
  <div class="main">
    <base-logo class="about_logo" mode="lupanalua"></base-logo>

    <div class="about">
      <div class="welcome">{{ about.welcome[getLanguage] }} !</div>
      <div class="topic">{{ about.mission[getLanguage] }}</div>
      <div class="topic">{{ about.developer[getLanguage] }}</div>
      <div class="topic">{{ about.subjects[getLanguage] }}</div>
      <div class="topic">{{ about.thank[getLanguage] }}</div>
      <div class="topic">Lu Shibata</div>
      <div class="topic">
        <div class="policy">{{ privacy.policy[getLanguage] }}</div>
        {{ privacy.text[getLanguage] }}
      </div>
    </div>
    <navi-button mode="middle"></navi-button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const about = ref({
  welcome: {
    en: "Welcome",
    pt: "bem-vindo",
  },
  mission: {
    en: "Lupanalua is a website about the environment.",
    pt: "Lupanalua é um website sobre meio ambiente.",
  },
  developer: {
    en: "It is a space created to test ideas as I learn how to code. I develop it during my free time and on a tight budget.",
    pt: "É um espaço criado para testar ideias à medida que aprendo a programar. Dedico-me a esse projeto nas horas vagas e com um orçamento limitado.",
  },
  subjects: {
    en: "The topics will revolve around the subjects that I studied - Geosciences, Environmental Education and Museology, so I will do my best to check all data used on this website.",
    pt: "Os assuntos irão girar em torno daqueles que estudei - Geociências, Educação Ambiental e Museologia e farei o possível de verificar todos os dados.",
  },
  thank: {
    en: "Thank you for visiting this website.",
    pt: "Muito obrigada por visitar este website.",
  },
});
const privacy = ref({
  policy: {
    en: "Privacy policy",
    pt: "Política de privacidade",
  },
  text: {
    en: "This website does not use cookies. No personal data is collected.",
    pt: "Este website não faz uso de cookies. Nenhum dado pessoal é coletado.",
  },
});
const store = useStore();

const getLanguage = computed(() => store.getters.getCurrentLanguage);
</script>

<style scoped>
.main {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom right, #3bc9db, #e3fafc);
}

.about {
  margin: 25rem;
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 2rem;
}

@media only screen and (max-width: 30em) {
  .about {
    margin-left: 20%;
  }
}

.topic {
  width: 60vw;

  font-size: 2rem;
  font-family: var(--font-primary);
  /* border-radius: 5px; */
  white-space: normal;
  /* box-shadow: 5px 5px 2px 1px rgba(0, 0, 100, .2); */
  position: relative;
}

.welcome {
  text-transform: capitalize;
  font-family: var(--font-display);
  color: grey;
  opacity: 0.5;
  width: max-content;
  height: max-content;
  z-index: -10;
  font-size: 10rem;
  position: fixed;
  top: 15rem;
  left: 15rem;
}

@media only screen and (max-width: 30em) {
  .welcome {
    font-size: 7rem;
    left: 20%;
  }
}

.policy {
  font-size: 3rem;
  font-family: var(--font-display);
  color: var(--font-grey-4);
  opacity: 0.5;
  z-index: -10;
  margin-bottom: 1rem;
}

.about_logo {
  background-color: white;
  border-radius: 50%;
}
</style>
