<template>
    <button class="btn navBtn" :class="mode">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: ['mode']
}
</script>

<style scoped>
.navBtn {
  
  fill: var(--color-white);
  transition: all 0.2s;
  position: fixed;
  
   }
  @media only screen and (max-width: 23.75em) {
    .navBtn {
      width: 2.5rem;
      height: 2.5rem; } }
  .navBtn:hover {
    transform: scale(1.5); 
}

.refresh {
  top: 55%;
  right: 1.5rem;
  width: 3rem;
  height: 3rem; }
  

.filter {
    top: 30%;
    right: 1.5rem;
    width: 3rem;
  height: 3rem;
}
/**minerals */
.goUp {
  bottom: 10%;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
}
.home-left{
  top: 50%;
  left: 20rem;
  width: 5rem;
  height: 5rem;
  transform: rotate(0.25turn);
  border-radius: 50%;
  fill:var(--color-primary-3)
}

.home-left:hover {
  transform: rotate(0.25turn) scale(1.2);
}
.home-right{
  top: 50%;
  right: 20rem;
  width: 5rem;
  height: 5rem;
  transform: rotate(0.75turn); 
  border-radius: 50%;
  fill:var(--color-primary-3)
  
}
.home-right:hover {
  transform: rotate(0.75turn) scale(1.2);
}

@media only screen and (max-width: 62.5em) { 
    .home-left,
    .home-right {
        top: 95%;
      } }

</style>