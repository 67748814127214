export default {
    getError(state){
        return state.error;
    },
    getOneMineral(state){
        return state.oneMineral;
    },
    /**
     * 
     * @returns {String} with Title in the appropriate language
     */
    getPageTitle(state){
        return state.currentTitle;
    },
    getQuerySuggestions(state){
        return state.querySuggestions.sort(new Intl.Collator(state.currentLanguage).compare);
    },
    getResults(state) {
        return state.results;
    },
    hasQuery(state){
        if (state.queryArray.length > 0 && state.results === 0) ;
        return state.queryArray;
    },
    loadSelectOptions(state){
        return state.selectOptions;
    },
    hasMineral(state){
        return state.hasMineral;
    }
}