<template>
  <div class="card">
    <div
      class="card__side card__side--front front"
      @click="showDetails(oneMineral)"
    >
      <base-photofiller v-if="!oneMineral.img_author" mode="card__filler"
        ><div class="oops">Oops!</div></base-photofiller
      >
      <figure v-else class="front__img">
        <picture>
          <source
            type="image/webp"
            :srcset="'./img/minerals/' + oneMineral.idName + '_medium.webp'"
          />
          <source
            media="(max-width:48em)"
            :srcset="
              './img/minerals/' +
              oneMineral.idName +
              '_small-1x.jpg 1x, ./img/minerals/' +
              oneMineral.idName +
              '_medium-1x.jpg 2x'
            "
          />
          <img
            :srcset="
              './img/minerals/' +
              oneMineral.idName +
              '_medium-1x.jpg 1x, ./img/minerals/' +
              oneMineral.idName +
              '_medium.jpg 2x'
            "
            alt="oneMineral.name[1]"
          />
        </picture>
        <figcaption class="front__caption">
          Image: {{ oneMineral.img_author }}, marked with
          {{ oneMineral.img_license }}
        </figcaption>
      </figure>

      <div class="front__identification">
        <h3 class="front__identification--name heading-3">
          {{ usingSlice(oneMineral.name[1]) }}
        </h3>
        <div
          class="front__identification--symbolHtml"
          v-html="usingSlice(oneMineral.symbol[1], 60)"
        ></div>
      </div>
    </div>
    <div class="card__side card__side--back back">
      <div class="back__details details">
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.type[0] }}</div>
          <div class="details__item--2">
            {{ usingSlice(oneMineral.type[1]) }}
          </div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.composition[0] }}</div>
          <div class="details__item--2">{{ oneMineral.composition[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">
            {{ usingSlice(oneMineral.crystal[0]) }}
          </div>
          <div class="details__item--2">{{ oneMineral.crystal[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.cleavage[0] }}</div>
          <div class="details__item--2">{{ oneMineral.cleavage[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.fracture[0] }}</div>
          <div class="details__item--2">{{ oneMineral.fracture[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.lustre[0] }}</div>
          <div class="details__item--2">{{ oneMineral.lustre[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.streak[0] }}</div>
          <div class="details__item--2">{{ oneMineral.streak[1] }}</div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.hardness[0] }}:</div>
          <div class="details__item--2">
            {{ oneMineral.hardness[1] }}
            {{
              oneMineral.hardness[2] === "-1"
                ? ""
                : `- ${oneMineral.hardness[2]}`
            }}
          </div>
        </div>
        <div class="details__item">
          <div class="details__item--1">{{ oneMineral.density[0] }}:</div>
          <div class="details__item--2">
            {{ oneMineral.density[1] }}
            {{
              oneMineral.density[2] === "-1" ? "" : `-${oneMineral.density[2]}`
            }}
          </div>
        </div>
      </div>

      <button class="back__dots" @click="showDetails(oneMineral)">
        <div class="dots__hor"></div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["show-details"]);
defineProps({
  oneMineral: Object,
  mode: String,
});

function showDetails(obj) {
  emit("show-details", obj);
}

function usingSlice(str, limit = 10) {
  if (!str) return;
  if (str.length > limit) return str.slice(0, limit) + "...";
  return str;
}
</script>

<style scoped>
.active {
  display: block;
}
.card {
  /* display: none; */
  perspective: 150rem;
  position: relative;
  height: 30rem;
  width: 25rem;
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin: 1rem;
}
@media only screen and (max-width: 81.25em) {
  .card {
    height: 22rem;
    border-radius: 0rem;
  }
}
@media only screen and (max-width: 64em) {
  .card {
    height: 18rem;
    width: 16rem;
  }
}
@media only screen and (max-width: 26.5em) {
  .card {
    height: 15.75rem;
    width: 14rem;
  }
}
.card__side {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  transition: all 0.5s;
  padding: 1rem;
}
@media only screen and (max-width: 81.25em) {
  .card__side {
    padding: 0.5rem;
  }
}
.card__side--front {
  background-color: var(--color-white);
}
.card__side--back {
  background-color: var(--color-white);
  transform: rotateY(180deg);
}
@media only screen and (max-width: 81.25em) {
  .card__side--back {
    display: none;
  }
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
@media only screen and (max-width: 81.25em) {
  .card:hover .card__side--front {
    transform: rotateY(0deg);
  }
}
.card:hover .card__side--back {
  transform: rotateY(0deg);
}
.front {
  display: grid;
  grid-template-rows: 80% 20%;
}
@media only screen and (max-width: 81.25em) {
  .front {
    grid-template-rows: 82% 18%;
    position: relative;
  }
}

.front__img {
  grid-row: 1/2;
  grid-column: 1/-1;
  width: 90%;
  margin: auto;
  overflow: hidden;
}
picture {
  margin: 0;
  width: 100%;
}
img {
  width: 100%;
  display: block;
  object-position: 50% 50%;
  object-fit: contain;
  overflow: hidden;
}
@media only screen and (max-width: 81.25em) {
  picture {
    margin-top: 0.5rem;
  }
}
@media only screen and (max-width: 64em) {
  picture {
    margin: 0;
  }
}

@media only screen and (max-width: 81.25em) {
  .front__caption {
    font-size: 0.5rem;
    font-weight: 200;
    text-align: center;
    max-height: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.front__identification {
  /* grid-row: 3/4; */
  margin-top: 0.5rem;
  grid-row: 2/3;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 81.25em) {
  .front__identification {
    grid-row: 2/3;
    grid-column: 1/-1;
    background-color: white;
    justify-content: center;
  }
}

.front__identification--name {
  text-transform: uppercase;
  text-align: center;
  color: var(--color-home-3);
  font-weight: 1000;
}

@media only screen and (max-width: 64em) {
  .front__identification--name {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 47.75em) {
  .front__identification--name {
    font-size: 1.6rem;
  }
}

.front__identification--symbolHtml {
  font-family: var(--font-primary);
  color: var(--color-home-3);
  font-size: 1.4rem;
  font-weight: 400;
  width: 100%;
  height: 1.5rem;
  text-overflow: ellipsis;
  text-align: center;
}

@media only screen and (max-width: 81.25em) {
  .front__identification--symbolHtml {
    display: none;
  }
}

.back {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  position: relative;
}
@media only screen and (max-width: 81.25em) {
  .back {
    display: none;
  }
}

.details__item {
  display: flex;
  flex-direction: row;
}
.details__item--1 {
  width: 50%;
}
.details__item--2 {
  width: 50%;
  border-bottom: 1px solid var(--color-primary-3);
}

.back__dots {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border: 1px solid var(--color-primary-2);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.dots__hor {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: relative;
}

.dots__hor,
.dots__hor::before,
.dots__hor::after {
  background-color: var(--color-primary-3);
}

.dots__hor::before,
.dots__hor::after,
.dots__ver::before,
.dots__ver::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
@media only screen and (max-width: 64em) {
  .dots__ver::before,
  .dots__ver::after {
    width: 0.3rem;
    height: 0.3rem;
  }
}

.dots__hor::before {
  position: absolute;
  left: -0.7rem;
}

.dots__hor::after {
  position: absolute;
  right: -0.7rem;
}

.back__dots:hover {
  background-color: var(--color-primary-3);
}

.back__dots:hover .dots__hor {
  background-color: white;
}

.back__dots:hover .dots__hor::before {
  background-color: white;
}

.back__dots:hover .dots__hor::after {
  background-color: white;
}

.oops {
  background-color: var(--color-primary-3);
  padding: 0.3rem;
}

/* front button */
button {
  display: none;
  width: 10%;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0.5rem;
  bottom: 2rem;
}
@media only screen and (max-width: 81.25em) {
  button {
    display: block;
  }
}
@media only screen and (max-width: 28.5em) {
  /* 450px */
  button {
    bottom: 1.8rem;
  }
}
button > div {
  margin: -1rem;
  color: var(--color-grey-3);
  font-size: 2rem;
}
button > div:hover {
  color: var(--color-primary-1);
  transform: scale(1.5);
}
</style>
