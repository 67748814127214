import { createRouter, createWebHistory } from "vue-router";

import AboutUs from "./pages/AboutUs.vue";
import AllMinerals from "./pages/minerals/AllMinerals.vue";
import NotFound from "./pages/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: AllMinerals },
    { path: "/minerals", component: AllMinerals },
    { path: "/minerais", component: AllMinerals },
    { path: "/about", component: AboutUs },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

export default router;
