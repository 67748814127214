<template>
  <router-view></router-view>
  <language-button @change-language="getBasePage"></language-button>
  <navi-button mode="middle"></navi-button>

  <base-finder class="minerals" :lang="getCurrentLanguage">
    <template v-slot:logo>
      <base-logo mode="minerals__logo"></base-logo>
    </template>
    <template v-slot:pageName>{{ getPageTitle }}</template>

    <template v-slot:header>
      <base-finderHeader
        @inputQuery="listenToQuery"
        :allSuggestions="getSuggestions"
        @submitQuery="listenToSpecificQuery"
      >
      </base-finderHeader>
    </template>

    <template v-slot:select>
      <base-select
        v-for="option in getSelectOptions"
        :key="option[1][0]"
        :select-option="option"
        @selected-option="listenToSelect"
      >
      </base-select>
    </template>

    <template v-slot:results>
      <teleport to="body">
        <mineral-details
          v-if="detailsAreVisible"
          :selected="selectedMineral"
          @toggle-visibility="toggleVisibility"
        >
        </mineral-details>
      </teleport>

      <teleport to="body">
        <transition name="openFilter">
          <mineral-filter
            v-if="filterIsVisible"
            @toggle-filter="toggleFilterVisibility"
            :filter-options="getSelectOptions"
            @new-query="listenToFilter"
          >
          </mineral-filter>
        </transition>
      </teleport>

      <teleport to="body">
        <transition name="sendError">
          <no-mineral
            v-if="getAllMinerals.length === 0"
            :has-query="hasQuery"
            @close-error="listenToRefresh"
          >
          </no-mineral>
        </transition>
      </teleport>

      <mineral-card
        v-for="mineral in getAllMinerals.slice(0, limit)"
        :key="mineral.idName"
        :one-mineral="mineral"
        @show-details="toggleDetails"
      ></mineral-card>

      <div class="loadingStatus" :class="active"></div>

      <action-button @click="listenToRefresh" mode="refresh">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 20l-1.359-2.038c-1.061.653-2.305 1.038-3.641 1.038-3.859 0-7-3.14-7-7h2c0 2.757 2.243 5 5 5 .927 0 1.786-.264 2.527-.708l-1.527-2.292h5.719l-1.719 6zm0-8c0-2.757-2.243-5-5-5-.927 0-1.786.264-2.527.708l1.527 2.292h-5.719l1.719-6 1.359 2.038c1.061-.653 2.305-1.038 3.641-1.038 3.859 0 7 3.14 7 7h-2z"
          />
        </svg>
      </action-button>

      <action-button @click="toggleFilterVisibility" mode="filter">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M1 0h22l-9 15.094v8.906l-4-3v-5.906z" />
        </svg>
      </action-button>

      <action-button @click="goUp" mode="goUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon-upArrow"
          viewBox="0 0 24 24"
        >
          <path
            d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-17 1h4v-8h2v8h4l-5 6-5-6z"
          />
        </svg>
      </action-button>
    </template>
  </base-finder>
</template>

<script setup>
import { computed, onBeforeMount, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue";

import MineralCard from "./MineralCard.vue";
import MineralDetails from "./MineralDetails.vue";
import MineralFilter from "./MineralFilter.vue";
import NoMineral from "./NoMineral.vue";
const store = useStore();

let detailsAreVisible = ref(false);
let filterIsVisible = ref(false);
let selectedMineral = ref({});
let filterSelection = ref([]);
let startinglimit = ref(15); // infinite scrolling
let limit = ref(); //infinite scrolling
let active = ref(true); // infinite scrolling

useSeoMeta({
  title: "lupanalua | minerals",
  description:
    "lupanalua is a website about environmental education and earth sciences. The minerals page features a database searchable by type (ore or rock forming mineral), composition, crystal system, cleavage, fracture, streak color, lustre, density, or hardness.",
  author: "lupanalua by Lu Shibata",
});

onBeforeMount(() => {
  getBasePage();
});

/**
 * calls function for triggering infinite scrolling
 */
onMounted(() => {
  observing();
});

/**
 * function that observes where the last element is positioned for lazy loading and infinite scrolling
 */
function observing() {
  const loading = document.querySelector(".loadingStatus");

  if (
    limit.value > getAllMinerals.value.length ||
    limit.value == getAllMinerals.value.length
  ) {
    active.value = false;
  }
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        limit.value += 10;
      }
      if (
        limit.value > getAllMinerals.value.length ||
        limit.value == getAllMinerals.value.length
      ) {
        active.value = false;
      }
    });
  });
  observer.observe(loading);
}

/** Computed **/

/**
 * @returns the whole page in the user language
 */
async function getBasePage() {
  store.dispatch("minerals/getPageTitle");
  await store.dispatch("minerals/getMineralNames");
  store.dispatch("minerals/getSelectOptions");
  await store.dispatch("minerals/getAllMinerals");
}

/**
 * @returns {String} of the user language
 */
const getCurrentLanguage = computed(() => {
  return store.getters.getCurrentLanguage;
});

/**
 * @returns {String} of the page name in the user language
 */
const getPageTitle = computed(() => {
  return store.getters["minerals/getPageTitle"];
});

/**
 * @returns {Array} of mineral names in the user language, according to query
 */
const getSuggestions = computed(() => {
  return store.getters["minerals/getQuerySuggestions"];
});

/**
 * @returns {Array} of Select section in user language and english
 */
const getSelectOptions = computed(() => {
  return store.getters["minerals/loadSelectOptions"];
});
/**
 * @returns {Array} of mineral Objects in user language
 */
const getAllMinerals = computed(() => {
  return store.getters["minerals/getResults"];
});
/**
 * toggle visibility of the infinite scrolling threshold and the starting limit value
 */
watch(getAllMinerals, () => {
  active.value = true;
  limit.value = startinglimit.value;
});

/**
 * @returns {Array} hasQuery to be shown to user in case of error, so the user knows that his query has no match
 */
const hasQuery = computed(() => {
  return store.getters["minerals/hasQuery"];
});

/** Methods **/
/**
 * action button to go up
 */
function goUp() {
  window.scrollTo(0, 0);
}
/**
 * @param {Array} of Arrays comprising two strings, e.g. section name and option ['type', 'ore mineral'] in English
 */
function listenToFilter(queryArray) {
  filterSelection.value = queryArray;
  store.dispatch("minerals/getSelect", queryArray);
}
/**
 * @param {String} q is the letter typed by user
 */
function listenToQuery(q) {
  store.dispatch("minerals/getQuerySuggestions", q);
}
/**
 * action button to reset rendering all minerals
 */
function listenToRefresh() {
  store.dispatch("minerals/getAllMinerals");
}
/**
 * @param {String} sq is the suggested mineral name that the user clicked
 */
function listenToSpecificQuery(sq) {
  store.dispatch("minerals/getOneMineral", sq);
}
/**
 * @param {Array} qa of select and clicked option in english, e.g.: ['cleavage', 'perfect'], ['density', 9]
 */
function listenToSelect(qa) {
  store.dispatch("minerals/getSelect", [qa]);
}
/**
 * click to open mineral details and send Object of the clicked mineral
 */
function toggleDetails(ob) {
  toggleVisibility();
  selectedMineral.value = ob;
}
/**
 * show mineral details
 */
function toggleVisibility() {
  detailsAreVisible.value = !detailsAreVisible.value;
}
/**
 * show filter
 */
function toggleFilterVisibility() {
  filterIsVisible.value = !filterIsVisible.value;
}
</script>

<style scoped>
.select {
  display: grid;
  grid-template-columns: repeat(9, minmax(max-content, 1fr));
  column-gap: 2px;
}
@media only screen and (max-width: 56.25em) {
  .select {
    display: none;
  }
}

.icon-upArrow {
  transform: rotate(180deg);
}

/* animation for filter*/

.openFilter-enter-from {
  transform: translateX(10rem);
}
.openFilter-enter-active {
  transition: all 0.5s ease-out;
}
.openFilter-enter-to {
  transform: translateX(0rem);
}

/* animation for error */
.sendError-enter-from {
  transform: translateY(10rem);
}
.sendError-enter-active {
  transition: all 0.5s ease-out;
}

.sendError-enter-to {
  transform: translateY(0rem);
}

.loadingStatus {
  visibility: hidden;
  text-align: center;
  font-weight: 400;
  margin: 0.5rem;
  padding: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  font-size: 2rem;
}

.active {
  visibility: visible;
}
</style>
