<template>
  <button class="btn language__btn" @click="changeLanguage">
    {{ getLanguageButton[1] }}
  </button>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["change-language"]);
const store = useStore();

function changeLanguage() {
  const previousLang = store.getters.getCurrentLanguage;
  if (previousLang === "pt") {
    store.dispatch("setCurrentLanguage", "en");
  } else {
    store.dispatch("setCurrentLanguage", "pt");
  }
  emit("change-language");
}

const getLanguageButton = computed(() => {
  return store.getters.languageBtn;
});
</script>

<style scoped>
.language__btn {
  font-family: var(--font-display);
  font-weight: 400;
  font-size: 1.5rem;
  text-transform: uppercase;
  min-width: 10rem;
  height: 2.5rem;
  border-radius: 2rem;
  margin: 0 0.5rem;
  box-shadow: 3px 3px 2px 1px var(--color-grey-3);
  background-color: var(--color-white);
  color: var(--color-grey-4);
  position: fixed;
  top: 4.5rem;
  right: 5rem;
  z-index: 2000;
}
@media only screen and (max-width: 56.25em) {
  .language__btn {
    top: 2rem;
    right: 2rem;
  }
}
.language__btn:hover {
  background-color: var(--color-primary-1);
  color: var(--color-home-1);
}
</style>
