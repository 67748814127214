
export default {
    addQuery(state, payload){
        let queryA = payload;
        if (this.state.language.currentLanguage === 'pt'){
            queryA = queryA.map(p => {
                return p.map(el => {
                    if (el === "crystal") el = "crystal system";
                    return this.state.language.translate[el]
                })
            })
        }
        
        return state.queryArray = queryA;
    },
    getAllMinerals(state, payload){
        return state.results = payload;
    },
    getPageTitle(state, payload){  
        return state.currentTitle = state.title[payload];
    },
    getMineralNames(state, payload){
        return state.mineralNames = payload;  
    },
    getQuerySuggestions(state, payload){
        return state.querySuggestions = payload;
    },
    getOneMineral(state, payload){
        return state.results = payload;
       
    },
    getOnlyOneMineral(state, payload){
        return state.oneMineral = payload;
       
    },
    getSelectOptions(state, payload){
        return state.selectOptions = payload;         
    },
    getSelect(state, payload){      
        return state.results = payload;
    },
    hasMineral(state, payload){
        return state.hasMineral = payload
    }
}


