/**
 *
 * @param {string} originalString
 * @param {object} dictionary contains translation of text, problem is the fem and male
 * @returns string with the translated string
 */
function translateString(originalString, dictionary) {
  // convert string into lowercase
  const stringConvertedtToLowercase = originalString.toLowerCase();
  const eachWord = /\b[a-z]+/gi;
  // create an array comprising each word of the string
  const dictEntries = [...stringConvertedtToLowercase.matchAll(eachWord)];
  // translate the string
  const translatedString = dictEntries.reduce((total, word) => {
    return (total = total.replace(word, dictionary[word]));
  }, stringConvertedtToLowercase);
  return translatedString;
}

/**
 *
 * @param {Object} suffixObj of Portuguese suffixes from masculine to feminine, used in Sections/fracture
 * @param {String} targetString in Portuguese whose suffix has to be changed
 * @returns
 */

function changeSuffix(suffixObj, targetString) {
  const objKeys = Object.keys(suffixObj);
  const stringToBeConverted = objKeys.reduce((total, k) => {
    let regexEl = new RegExp(`${k}\\b`, "g");
    return (total = total.map((el) => el.replace(regexEl, suffixObj[k])));
  }, targetString);
  return stringToBeConverted;
}

/**
 *
 * @param {String} languageId 'en' or 'pt'
 * @param {Object} mineral in english
 * @param {Object} translation, that is: glossary of english:portuguese
 * @returns {Object} of mineral in the appropriate language
 */
const createMineralObject = function (languageId, mineral, translation) {
  if (languageId === "pt") {
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      maximumFractionDigits: 2,
    });
    return {
      idName: mineral.name,
      name: ["nome", mineral.pt_name],
      symbol: ["fórmula", mineral.symbol_1],
      type: [translation["type"], translation[mineral.type]],
      composition: [
        translation["composition"],
        translation[mineral.composition],
      ],
      crystal: [
        translation["crystal system"],
        translation[mineral.crystal_system],
      ],
      cleavage: [
        translation["cleavage"],
        translateString(mineral.cleavage, translation),
      ],
      fracture: [
        translation["fracture"],
        translateString(mineral.fracture, translation),
      ],
      lustre: [
        translation["lustre"],
        translateString(mineral.lustre, translation),
      ],
      streak: [translation["streak"], mineral.pt_streak],
      hardness: [
        translation["hardness"],
        nf.format(mineral.hardness_min),
        nf.format(mineral.hardness_max),
      ],
      density: [
        translation["density"],
        nf.format(mineral.density_min),
        nf.format(mineral.density_max),
      ],
      img: `./img/photos/${mineral.name}_large.webp`,
      img_url: mineral.img_url,
      img_author: mineral.img_author,
      img_aut_url: mineral.img_aut_url,
      img_license: mineral.img_license,
      img_alt: mineral.img_alt,
      img_mindat: mineral.img_mindat,
    };
  } else {
    const nf = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    });
    return {
      idName: mineral.name,
      name: ["name", mineral.name],
      symbol: ["formula", mineral.symbol_1],
      type: ["type", mineral.type],
      composition: ["composition", mineral.composition],
      crystal: ["crystal system", mineral.crystal_system],
      cleavage: ["cleavage", mineral.cleavage],
      fracture: ["fracture", mineral.fracture],
      lustre: ["lustre", mineral.lustre],
      streak: ["streak", mineral.streak],
      hardness: [
        "hardness",
        nf.format(mineral.hardness_min),
        nf.format(mineral.hardness_max),
      ],
      density: [
        "density",
        nf.format(mineral.density_min),
        nf.format(mineral.density_max),
      ],
      img: `./img/photos/${mineral.name}_large.webp`,
      img_url: mineral.img_url,
      img_author: mineral.img_author,
      img_aut_url: mineral.img_aut_url,
      img_license: mineral.img_license,
      img_alt: mineral.img_alt,
      img_mindat: mineral.img_mindat,
    };
  }
};

export default {
  getPageTitle(context) {
    const currentLanguage = this.state.language.currentLanguage;
    context.commit("getPageTitle", currentLanguage);
  },
  /**
   *
   * @returns {Array} with mineral names in the user language
   */
  async getMineralNames(context) {
    const currentLanguage = this.state.language.currentLanguage;
    let mineralNames;

    if (currentLanguage === "pt") {
      mineralNames = await this.state.minerals.mineralDatabase
        .slice()
        .map((el) => el.pt_name)
        .sort();
      return context.commit("getMineralNames", mineralNames);
    }

    // mineralNames = await this.state.minerals.nameDatabase.en.sort();
    mineralNames = await this.state.minerals.mineralDatabase
      .slice()
      .map((el) => el.name)
      .sort();
    context.commit("getMineralNames", mineralNames);
  },
  /**
   * @returns {Array} with key-values of sections in current language and english
   */
  async getSelectOptions(context) {
    const currentLanguage = this.state.language.currentLanguage;
    // Select Options
    const translate = this.state.language.translate;
    const suffixFix = this.state.language.suffixFix;
    let allSelect = this.state.minerals.allSelectOptions;
    let selectOptions;

    if (currentLanguage === "pt") {
      const selectKeys = Object.keys(allSelect);
      const selectValues = Object.values(allSelect);
      const ptSelectKeys = await selectKeys.map((k) => translate[k]);
      const ptSelectValues = await selectValues.map((v) =>
        v.map((val) => translate[val])
      );
      selectOptions = await ptSelectKeys.map((k, i) => {
        if (selectKeys[i] === "fracture") {
          const tobeModified = changeSuffix(suffixFix, ptSelectValues[i]);
          return [
            [k, tobeModified],
            [selectKeys[i], selectValues[i]],
          ];
        } else {
          return [
            [k, ptSelectValues[i]],
            [selectKeys[i], selectValues[i]],
          ];
        }
      });
    }
    if (currentLanguage === "en") {
      selectOptions = await Object.entries(allSelect).map((so) => {
        return [
          [so[0], so[1]],
          [so[0], so[1]],
        ];
      });
    }
    context.commit("getSelectOptions", selectOptions);
  },
  /**
   * @returns {Object} of all Minerals in the appropriate language
   */
  async getAllMinerals(context) {
    const currentLanguage = this.state.language.currentLanguage;
    const dictionary = this.state.language.translate;
    const results = await this.state.minerals.mineralDatabase.map((min) =>
      createMineralObject(currentLanguage, min, dictionary)
    );
    context.commit("getAllMinerals", results);
  },
  /**
   *
   * @param {String} payload of letter typed by user in the input
   * @returns {Array} querySuggestions of mineral names that matches the payload
   */
  async getQuerySuggestions(context, payload) {
    let reg = new RegExp(`\\b${payload}`, "i");
    const mineralNameArray = await this.state.minerals.mineralNames.slice();
    const querySuggestions = await mineralNameArray.filter((el) =>
      el.match(reg)
    );
    context.commit("getQuerySuggestions", querySuggestions);
  },
  /**
   *
   * @param {String} payload is the mineral name clicked by the user in Search
   * @returns {Object} results comprising one mineral
   */
  async getOneMineral(context, payload) {
    const data = this.state.minerals.mineralDatabase;
    const dictionary = this.state.language.translate;
    const languageId = this.state.language.currentLanguage;
    // if the language is portuguese, use collator to sort
    const collator = new Intl.Collator(languageId, {
      usage: "search",
      sensitivity: "base",
    });
    let match;
    if (languageId === "pt") {
      match = await data.find(
        (dat) => collator.compare(dat[languageId + "_name"], payload) === 0
      );
    } else {
      match = await data.find(
        (dat) => collator.compare(dat.name, payload) === 0
      );
    }
    if (!match) {
      return;
    }
    const results = [createMineralObject(languageId, match, dictionary)];
    context.commit("getOneMineral", results);
  },
  /**
   * payload from clicked select options or filter
   * @param {Array} payload of select and option in english, e.g.: ['cleavage', 'perfect'], ['density', 9]
   * @returns {Array} of Objects of Minerals that match the select and option parameters
   */
  async getSelect(context, payload) {
    const res = this.state.minerals.mineralDatabase.slice();
    context.commit("addQuery", payload);

    let queryArray = payload;

    // Adjust some terms
    queryArray = queryArray.map((el) => {
      if (el[1] === "cubic") {
        el[1] = "isometric";
      }
      let sectionName;
      let sectionQuery;
      // let regex;
      if (el[0] === "hardness" || el[0] === "density") {
        sectionQuery = el[1];
      } else {
        sectionQuery = el[1].split(" ")[0];
        sectionQuery = new RegExp(`\\b${sectionQuery}`, "i");
      }

      if (el[0] === "crystal system" || el[0] === "crystal") {
        sectionName = "crystal_system";
      } else {
        sectionName = el[0];
      }

      return [sectionName, sectionQuery];
    });

    const queryMatches = await queryArray.reduce((total, cond) => {
      // hardness and density input refers to the minimum number
      if (cond[0] === "hardness" || cond[0] === "density") {
        total = total.filter((t) => {
          if (
            t[cond[0] + "_min"] === cond[1] ||
            (t[cond[0] + "_min"] > cond[1] && t[cond[0] + "_max"] < cond[1]) ||
            t[cond[0] + "_max"] === cond[1] ||
            (t[cond[0] + "_min"] < cond[1] && t[cond[0] + "_max" === -1])
          ) {
            return t;
          }
        });
        return total;
      }
      // nommetallic lustre matches all those that are nonmetallic, maybe should be 'transparent' and 'opaque'
      if (cond[0] === "lustre" && cond[1].toString() == "/\\bnonmetallic/i") {
        total = total.filter((t) => {
          let condition = /\bmetallic/i;
          let index = t[cond[0]];
          if (!condition.test(index)) {
            return t;
          }
        });

        return total;
      }

      //filter to find all those that match the conditions

      total = total.filter((t) => {
        if (cond[1].test(t[cond[0]])) return t;
      });

      return total;
    }, res);

    const languageId = this.state.language.currentLanguage;
    const translate = this.state.language.translate;
    // transform the results in object of minerals to be rendered in the user language
    const results = await queryMatches.map((min) =>
      createMineralObject(languageId, min, translate)
    );

    context.commit("getSelect", results);
  },
  async getMineralParams(context, payload) {
    //1 check if the mineral exists
    let reg = new RegExp(`\\b${payload}`, "i");
    const languageId = this.state.language.currentLanguage;
    const mineralNames = await this.state.minerals.nameDatabase[languageId];
    const testName = reg.test(mineralNames);

    // 2 If it does not exist, send error
    if (testName === false) {
      context.commit("hasMineral", false);
      return;
    }

    // 3 If it exists, get mineral

    const data = this.state.minerals.mineralDatabase;
    const dictionary = this.state.language.translate;

    const collator = new Intl.Collator(languageId, {
      usage: "search",
      sensitivity: "base",
    });
    let match;
    if (languageId === "pt") {
      match = await data.find(
        (dat) => collator.compare(dat[languageId + "_name"], payload) === 0
      );
    } else {
      match = await data.find(
        (dat) => collator.compare(dat.name, payload) === 0
      );
    }
    const results = createMineralObject(languageId, match, dictionary);

    context.commit("getOnlyOneMineral", results);
  },
};
