<template>
    <base-dialog @click="closeError" mode="error">          
        <template v-slot:dialog>        
            <div>
            <close-button mode="details" @click="closeError"></close-button>
            <h3>{{ message[getCurrentLanguage] }}</h3>
            <ul>
                <li v-for="query in hasQuery" :key="query">{{query[0]}}: {{query[1] === undefined ? "?" : query[1] }}</li>
            </ul>  
            </div>            
        </template>        
    </base-dialog>
</template>

<script setup>

import { defineEmits, defineProps, ref, computed } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['close-error']);
defineProps({ hasQuery: Array })

// let query = ref([]);
const message = ref({
                en: "Oops! No match in this database",
                pt: "Opa! Não achei nesse banco de dados"
             })

function closeError(){
         emit('close-error')
        }

const store = useStore();
const getCurrentLanguage = computed(()=> {
    return store.getters.getCurrentLanguage;
})    
    

</script>


<style scoped>


h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    font-family: var(--font-display);
    text-transform: uppercase;
}

ul {
    text-align: center;
    font-size: 2rem;
    list-style: none;
    
}

ul li::before {
  content: "✖︎";
  
  margin-right: 0.7rem;
  color: red;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  line-height: 1.2em;
}


</style>