<template>
  <div class="search">
    <form class="form" @submit.prevent="submitForm">
      <input
        type="text"
        placeholder="name / nome"
        autocapitalize="none"
        autocomplete="off"
        v-model="generalQuery"
      />
      <div class="form__preview">
        <ul v-if="generalQuery">
          <li
            v-for="suggestion in allSuggestions"
            :key="suggestion"
            @click="clicked(suggestion)"
          >
            {{ suggestion }}
          </li>
        </ul>
      </div>
      <button class="btn form__btn">
        <svg
          class="icon__find"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"
          />
        </svg>
      </button>
    </form>
    <button @click="clearForm" class="btn form__clear">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 20l-1.359-2.038c-1.061.653-2.305 1.038-3.641 1.038-3.859 0-7-3.14-7-7h2c0 2.757 2.243 5 5 5 .927 0 1.786-.264 2.527-.708l-1.527-2.292h5.719l-1.719 6zm0-8c0-2.757-2.243-5-5-5-.927 0-1.786.264-2.527.708l1.527 2.292h-5.719l1.719-6 1.359 2.038c1.061-.653 2.305-1.038 3.641-1.038 3.859 0 7 3.14 7 7h-2z"
        />
      </svg>
    </button>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";

const emit = defineEmits(["submit-query", "input-query"]);
defineProps({ allSuggestions: Array });

let generalQuery = ref("");
let specificQuery = ref("");

/**
 * method to emit {String} of mineral name typed by the user
 */
function submitForm() {
  if (generalQuery.value !== "") {
    specificQuery.value = generalQuery.value;
  }
  generalQuery.value = "";
  emit("submit-query", specificQuery.value);
}
/**
 * method to emit {String} of mineral name clicked by the user
 */

function clicked(sug) {
  specificQuery.value = sug;
  generalQuery.value = "";
  emit("submit-query", specificQuery.value);
}
function clearForm() {
  specificQuery.value = "";
  generalQuery.value = "";
}

/**
 * watcher
 * @param {String} generalQuery is any typed letter
 */
watch(generalQuery, function () {
  emit("input-query", generalQuery.value);
});
</script>

<style scoped>
h1 {
  text-transform: capitalize;
}

.search {
  grid-column: search-start / search-end;
  grid-row: 1/-1;
  animation: rightToLeft 2s ease-in;
  align-self: end;
  margin-bottom: 1.75rem;
  margin-left: 2rem;
  display: flex;
}
@media only screen and (max-width: 56.25em) {
  .search {
    grid-column: logo-start/heading-end;
    grid-row: 2 / 3;
    justify-self: center;
  }
}
/* @media only screen and (max-width: 43.74em) {
    .search {
      grid-column: logo-start / language-end; } } */

@media only screen and (max-width: 23.75em) {
  .search {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

.form {
  position: relative;
}

input {
  font-size: 1.6rem;
  margin-right: -3rem;
  border-radius: 1rem;
  border: none;
  width: 30rem;
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
}
@media only screen and (max-width: 56.25em) {
  input {
    width: 35rem;
  }
}
@media only screen and (max-width: 48em) {
  input {
    width: 20rem;
  }
}
input:focus {
  outline: none;
  box-shadow: 5px 5px 20px var(--color-grey-1);
}

.form__preview {
  margin-left: 1rem;
  width: 28rem;
  position: absolute;
  top: 2.5rem;
  z-index: 300;
  background-color: var(--color-grey-1);
}

ul {
  font-size: 1.5rem;
  padding-left: 0.5rem;
  list-style: none;
}

li:hover {
  background-color: var(--color-grey-2);
}
.form__btn {
  color: var(--color-grey-3);
}
.icon__find {
  width: 1.3rem;
  height: 1.3rem;
  stroke: var(--color-grey-4);
  fill: var(--color-primary-2);
}

.form__clear {
  margin-left: 2rem;
  align-self: center;
  fill: white;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
}
@media only screen and (max-width: 23.75em) {
  .form__clear {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.form__clear:hover {
  transform: scale(1.2) translateX(2px);
}
.form__clear:active {
  transform: translateY(2px);
}

/* animation */

@keyframes rightToLeft {
  0% {
    transform: translateX(5rem);
  }
  100% {
    transform: translateX(0rem);
  }
}
</style>
