<template>
  <div class="finder">
    <div class="header">
      <div class="header__logo">
        <slot name="logo"></slot>
      </div>
      <h1 class="pageName heading-1">
        <slot name="pageName"></slot>
      </h1>
      <slot name="header"></slot>
    </div>

    <div class="select" id="select">
      <slot name="select"></slot>
    </div>

    <div class="results">
      <slot name="results"></slot>
    </div>
  </div>
</template>

<style>
.heading-1 {
  color: var(--color-grey-4);
  font-family: var(--font-display);
  font-weight: 400;
}

.heading-3 {
  color: var(--color-grey-4);
  font-family: var(--font-display);
  font-weight: 400;
}

.heading-4 {
  color: var(--color-grey-4);
  font-family: var(--font-display);
  font-weight: 400;
}

.finder {
  display: grid;
  grid-template-rows:
    [header-start] 15vh [header-end select-start] 5vh [select-end results-start] minmax(
      80vh,
      auto
    )
    [results-end];
  position: relative;
}
@media only screen and (max-width: 56.25em) {
  .finder {
    grid-template-rows:
      [header-start] 15vh [header-end results-start] minmax(85vh, auto)
      [results-end];
  }
}

.header {
  grid-row: header-start / header-end;
  position: fixed;
  height: 15vh;
  z-index: 1000;
  display: grid;
  grid-template-columns: [logo-start]8vw[logo-end heading-start] 37vw [heading-end search-start] 57vw [search-end];
  grid-template-rows: 70% 30%;
}
@media only screen and (max-width: 56.25em) {
  .header {
    grid-template-columns: [logo-start] 12vw [logo-end heading-start] 88vw [heading-end];
    border-bottom: 1px solid var(--color-white);
  }
}

.header__logo {
  animation: leftToRight 2s linear;
  grid-column: logo-start / logo-end;
  grid-row: 1 / -1;
  align-self: center;
  justify-self: center;
}
@media only screen and (max-width: 26.5em) {
  .header__logo {
    grid-row: 1/2;
  }
}

.pageName {
  text-transform: capitalize;
  grid-column: heading-start / heading-end;
  grid-row: 1/-1;
  align-self: end;
  justify-self: end;
  animation: headerDown 2s ease-in;
}
@media only screen and (max-width: 56.25em) {
  .pageName {
    grid-column: logo-start/search-end;
    grid-row: 1 / 2;
    align-self: flex-end;
    justify-self: center;
  }
}
@media only screen and (max-width: 26.5em) {
  .pageName {
    align-self: flex-end;
  }
}

.select {
  grid-row: select-start / select-end;
}

@media only screen and (max-width: 56.25em) {
  #select {
    display: none;
  }
}

.results {
  grid-row: results-start/results-end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-right: 4rem;
}
@media only screen and (max-width: 48em) {
  .results {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 23.75em) {
  .results {
    padding-right: 3rem;
  }
}

/** MINERALS header */
.minerals > div:nth-child(1) {
  background-color: var(--color-primary-2);
}
/** MINERALS select */
.minerals > div:nth-child(2) {
  display: grid;
  grid-template-columns: repeat(9, minmax(max-content, 1fr));
  column-gap: 2px;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-grey-3);
  margin-right: 1rem;
}
@media only screen and (max-width: 56.25em) {
  .select {
    display: none;
  }
}
/** MINERALS results */
.minerals > div:nth-child(3) {
  background-image: linear-gradient(
    to bottom,
    var(--color-primary-2),
    var(--color-primary-3)
  );
}

/** ROCKS header */
.rocks > div:nth-child(1) {
  /* background-color: var(--color-primary-6); */
  background-color: #dbe4ff;
}
/** ROCKS select */
.rocks > div:nth-child(2) {
  background-color: #dbe4ff;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 56.25em) {
  .select {
    display: none;
  }
}
/** ROCKS results */
.rocks > div:nth-child(3) {
  /* background-image: linear-gradient(to bottom, var(--color-primary-6), #fef08a); */
  background-image: linear-gradient(to bottom, #dbe4ff, #edf2ff);
}

/* Animations */
@keyframes headerDown {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
</style>
