<template>
  <div class="backdrop">
    <close-button @click="toggleFilter" mode="details"></close-button>
    <form @submit.prevent="submitFilter" class="filter__content">   
      <div v-for="filterOption in filterOptions" :key=filterOption[1][0]>
        <div v-if="filterOption[1][0] === 'hardness' || filterOption[1][0] === 'density'" class="content__category category">
          <label :for=filterOption[1][0] class="category__label">{{filterOption[0][0]}}</label> 
          <input
            type="number"
            class="category__input"
            :id=filterOption[1][0]
            :name=filterOption[1][0]
            min="1"
            :max="`${filterOption[1][0] === 'hardness' ? 10 : 20 }`"
            placeholder=0
            @change="newQueryNumber"/>
          <p class="category__placeholder">(Min: 1 - Max: {{filterOption[1][0] === "hardness" ? "12" : "19"}})</p>
        </div>

        <div v-else class="content__category category">
          <label :for=filterOption[1] class="category__label">{{filterOption[0][0]}}</label> 
            <div class="category__select">
              <select :name=filterOption[1][0] :id="`${filterOption[1][0]}`" v-model="inputText" @change="newQuery">
                <option value="0">Select a type</option> 
                <option  v-for="filterItem in filterOption[0][1]" :key="filterItem" :value="filterOption[1][1][filterOption[0][1].indexOf(filterItem)]">{{filterItem}}</option>
              </select>
            </div>      
        </div>
      </div>    
      <button class="btn filter__filterBtn filterBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.906 2.841c1.104-2.412-7.833-2.841-10.907-2.841-2.934 0-12.01.429-10.906 2.841.508 1.11 8.907 12.916 8.907 12.916v5.246l4 2.997v-8.243s8.398-11.806 8.906-12.916zm-10.901-.902c4.243 0 8.144.575 8.144 1.226s-3.9 1.18-8.144 1.18-8.042-.528-8.042-1.18 3.799-1.226 8.042-1.226z"/></svg>
        <h4>Find</h4>
      </button>
    </form>      
  </div>
</template>

<script setup>

import { defineEmits, defineProps, ref } from 'vue';

const emit = defineEmits(['toggle-filter', 'new-query']);
defineProps({ filterOptions: Array });
let keyValue = ref({
          type:'',
          composition:'',
          crystal: '',
          cleavage:'',
          fracture: '',
          lustre:'',
          streak:'',
          hardness: null,
          density: null
          })
let inputText = ref('')
        
/**  Methods **/

/**
 * @param {Object} of the event
 * keyItem is {string} of the section in english: 'type', 'cleavage', ...
 * inputText is {string} of the option in english: 'ore mineral', ...
 */
function newQuery(value){
  let keyItem = value.target.id;
  if (keyItem === "crystal system") keyItem = "crystal";
  keyValue.value[keyItem] = inputText.value;
  }

/**
 * @param {Object} of the event
 * keyItem is {string} of the section in english: 'hardness' or 'density', ...
 * value.target.value is {Number} of the option 
 */       
function newQueryNumber(value){
  const keyItem = value.target.id;
  keyValue.value[keyItem] = +value.target.value;
  }

/**
 * @returns {Boolean} false, to close filter
 */
function toggleFilter(){
  emit('toggle-filter');
  }

/**
 * @returns {Array} comprising another Array of section name and option, e.g. ['type', 'ore mineral'];
 */
function submitFilter(){
  let queryArray = Object.entries(keyValue.value);
  queryArray = queryArray.filter(el => el[1] !== '' && el[1] !== null);
  emit('new-query', queryArray);
  toggleFilter()
  }


</script>



<style scoped>
label {
  font-size: 2.8rem; }

input {
  font-size: 2.5rem; }

p {
  font-size: 2rem; }


.backdrop {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-primary-3);
  z-index: 5000;
  cursor: pointer; 
}
.filter__content {
  position: absolute;
  top: 20%;
  left: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem; }
  @media only screen and (max-width: 43.74em) {
    .filter__content {
      top: 10%;
      left: 15%; } }
  @media only screen and (max-width: 26.5em) {
    .filter__content {
      top: 15%; } }
.content__category {
  font-family: var(--font-display);
  font-size: 2rem;
  text-transform: uppercase; }

.content__category select {
    font-size: 1.6rem;
    padding: .5rem; }
.category {
  display: grid;
  grid-template-rows: 4rem;
  grid-template-columns: 50% 25% 25%;
  box-sizing: border-box;
  align-items: center;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
@media only screen and (max-width: 43.74em) {
    .category {
      display: grid;
      grid-template-rows: 4rem minmax(1fr, 4rem);
      margin-bottom: 1rem; } }
.category__label {
    grid-column: 1 / 2;
    width: 25vw; }
@media only screen and (max-width: 43.74em) {
    .category__label {
        grid-row: 1/2;
        grid-column: 1/-1;
        width: 70vw; } }
.category__select {
    grid-column: 2/4;
    width: 25vw;
    cursor: pointer;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative; }
 @media only screen and (max-width: 43.74em) {
      .category__select {
        grid-row: 2/3;
        grid-column: 1/-1;
        width: 60vw; } }
.category__select select {
      font-family: inherit;
      font-size: inherit;
      text-transform: uppercase;
      width: 100%;
      appearance: none;
      border: none;
      outline: none;
      grid-area: select; }
.category__select select::-ms-expand {
      display: none; }
.category__select:focus {
      background-color: var(--color-primary-4); }
.category__select::after {
    content: "";
    margin-right: 1rem;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--color-primary-1);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end; }
.category__input {
    grid-column: 2/3;
    width: 7rem; }
@media only screen and (max-width: 43.74em) {
      .category__input {
        grid-row: 1/2;
        grid-column: 2/3; } }
@media only screen and (max-width: 43.74em) {
    .category p {
      grid-row: 1/2;
      grid-column: 3/4;
      width: 30vw; } }
.category input::-webkit-outer-spin-button,
.category input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
.category input[type=number] {
    -moz-appearance: textfield; }
  .category__placeholder {
    grid-column: 3/4; }

.filter__filterBtn {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 40%;
  right: -15rem; }
@media only screen and (max-width: 43.74em) {
    .filter__filterBtn {
      width: 4rem;
      height: 4rem;
      top: 40%;
      left: 90%;
       } }

.filter__btn:hover {
  transform: scale(1.2); }


</style>